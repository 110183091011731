import _fs from "fs";
import _path from "path";
var exports = {};
// confDir
;
(function () {
  var fs = _fs;
  var path = _path;

  exports = exports = function getConfDir(dir, mod, callback) {
    if (typeof mod != "string" || /\//.test(mod)) {
      callback(new Error("Illegal argument 'modname'."));
      return;
    }

    var dirmod = "." + mod;
    var lastResult = ""; // find dirname by recursively walking up the path hierarchy

    function findDirRec(dir, callback) {
      var result = path.resolve(dir, dirmod);
      fs.stat(result, function stat(err, stat) {
        if (err || !stat.isDirectory()) {
          if (lastResult == result) {
            callback(new Error("No configuration directory found."));
            return;
          }

          lastResult = result;
          findDirRec(path.resolve(dir, ".."), callback);
        } else {
          callback(null, result);
        }
      });
    }

    findDirRec(dir, callback);
  };
}).call(exports);
export default exports;